import { OrganizationDTO } from '~/domain/organization/dto/OrganizationDTO'

export interface Props {
  organizations: OrganizationDTO[]
  cols?: number
}

export function useOrganizationItemList(props: Props) {
  const { organizations, cols = 3 } = props

  return {
    organizations,
    cols,
  }
}
