import clsx from 'clsx';
import { useOrganizationItemList, Props } from './useOrganizationItemList';
import { OrganizationItem } from '~/domain/organization/components/OrganizationItem/OrganizationItem';
import styles from './OrganizationItemList.module.css';

export function OrganizationItemList(props: Props) {
  const { organizations, cols } = useOrganizationItemList(props);

  return (
    <div
      className={clsx(
        styles.list,
        cols === 2 && styles.cols2,
        cols === 3 && styles.cols3
      )}>

      {organizations.map((organization) => {
        return (
          <div key={organization.id}>
            <OrganizationItem organization={organization} />
          </div>);

      })}
    </div>);

}