import { typedjson, useTypedLoaderData } from 'remix-typedjson'
import type { MetaFunction } from '@remix-run/node'
import { useOutletContext } from '@remix-run/react'
import { GetUsersUseCase } from '~/domain/user/usecases/GetUsersUseCase'
import { GetOrganizationUseCase } from '~/domain/organization/usecases/GetOrganizationsUseCase'
import { BrandHero } from '~/application/components/BrandHero/BrandHero'
import { Container } from '~/application/components/Container/Container'
import { Content } from '~/application/components/Content/Content'
import { UserItemList } from '~/domain/user/components/UserItemList/UserItemList'
import { OrganizationItemList } from '~/domain/organization/components/OrganizationItemList/OrganizationItemList'
import { getMeta } from '~/application/services/metaService'
import { Heading } from '~/application/components/Heading/Heading'
import { Card } from '~/application/components/Card/Card'
import { Button } from '~/application/components/Button/Button'
import { FrontContext } from '~/routes/_front'

export const meta: MetaFunction = ({ location }) => {
  return getMeta({
    title: '',
    url: location.pathname,
  })
}

export async function loader() {
  const getUsersUseCase = GetUsersUseCase.create()
  const getOrganizationsUseCase = GetOrganizationUseCase.create()
  const [users, organizationsResult] = await Promise.all([
    getUsersUseCase.execute(),
    getOrganizationsUseCase.execute(),
  ])

  if (organizationsResult.isErr()) {
    throw new Error('Organizations')
  }

  return typedjson({
    users,
    organizations: organizationsResult.value.data,
  })
}

export default function Index() {
  const outletContext = useOutletContext<FrontContext>()
  const data = useTypedLoaderData<typeof loader>()

  return (
    <>
      <BrandHero />
      <Content>
        <Container>
          {!outletContext.loginUser && (
            <div className="mb-24">
              <Card title="BeBloomingをはじめよう！">
                <div className="md:flex md:justify-between pt-8">
                  <p>
                    BeBloomingは個人の活動を幅広く知ってもらうための情報発信、企業からのスポンサー・サポートを募集することができます。
                    <br />
                    自分の魅力を発信して活動をサポートしてくれる企業・団体を探しましょう！
                  </p>
                  <div>
                    <Button to="/signup" color="primary">
                      アカウントを登録
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          )}

          <section className="mb-32">
            <div className="mb-8">
              <Heading title="New Users" subTitle="ユーザー一覧" />
            </div>
            <UserItemList users={data.users} />
          </section>
          <section>
            <div className="mb-8">
              <Heading title="Sponsors" subTitle="スポンサー一覧" />
            </div>
            <OrganizationItemList organizations={data.organizations} />
          </section>
        </Container>
      </Content>
    </>
  )
}
