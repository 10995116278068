import { OrganizationDTO } from '~/domain/organization/dto/OrganizationDTO'
import { getPhotoUrl } from '~/domain/organization/services/OrganizationDTOService'

export interface Props {
  organization: OrganizationDTO
}

export function useOrganizationItem(props: Props) {
  const { organization } = props

  return {
    organization,
    photoUrl: getPhotoUrl(organization),
  }
}
