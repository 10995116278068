import { Link } from '@remix-run/react';
import { Card } from '~/application/components/Card/Card';
import { Avatar } from '~/application/components/Avatar/Avatar';
import { useOrganizationItem, Props } from './useOrganizationItem';
import styles from './OrganizationItem.module.css';

export function OrganizationItem(props: Props) {
  const { organization, photoUrl } = useOrganizationItem(props);

  return (
    <Link to={`/organizations/${organization.id}`} className={styles.link}>
      <Card>
        <div className={styles.inner}>
          <div className={styles.avatar}>
            <Avatar src={photoUrl} size="lg" />
          </div>
          <div className={styles.name}>{organization.name}</div>
        </div>
      </Card>
    </Link>);

}