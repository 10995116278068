import { Container } from '~/application/components/Container/Container';
import styles from './BrandHero.module.css';

export const BrandHero = () => {
  return (
    <div className={styles.hero}>
      <Container>
        <h1 className={styles.title}>
          小さな光が、大きな輝きへ。
          <br />
          共に育む、希望の軌跡。
        </h1>
        <p className={styles.subTitle}>
          BeBloomingであなたのサポーターと出会おう！
        </p>
      </Container>
    </div>);

};